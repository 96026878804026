import axios from "axios";
import store from "@/store";
import router from "@/router/router";
import { toRaw } from 'vue'

export let backendUrl = process.env.VUE_APP_API_URL;
//TODO: Декомпозиция
const axiosApi = axios.create({
    baseURL: backendUrl + '/v1', headers: { 'Content-Type': 'application/json' }
})

axiosApi.interceptors.request.use(function (config) {
    // Do something before request is sent
    // store.state.anyLoading = true;
    return config;
}, function (error) {
    // store.state.anyLoading = false;


    return Promise.reject(error);
});
axiosApi.interceptors.response.use(function (response) {

    if (process.env.NODE_ENV === 'development') {
        setTimeout(() => {
            store.state.anyLoading = false;
        }, 500)
    } else {
        store.state.anyLoading = false;
    }

    return response;
}, function (error) {
    store.state.anyLoading = false;
    if (error.code === 'ERR_NETWORK') router.push('/ERR_NETWORK');
    switch (error.response.status) {
        case 500:
            router.push('/500');
            break;
        case 403:
            router.push('/403');
            break;
        case 401:
            store.dispatch('AuthModule/onLogout');
    }
    console.log(error)
    return Promise.reject(error);
});

export default {
    sendTeamConsultationRequest(teamId, phone, message) {
        return axiosApi.post("/teams/" + teamId + "/consultation", {
            phone: phone,
            message: message
        }, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    sendRequestAddUsersToCompany(companyId, users) {
        return axiosApi.post("/company/" + companyId + "/requests-add-users?users=" + users, {}, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    sendRequestAddUsersToCompanyPayment(companyId, users, promo) {
        return axiosApi.post("/company/" + companyId + "/requests-add-users-pay?users=" + users + "&promo=" + promo, {}, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    checkPromoCodeToCompanyPayment(companyId, promo) {
        return axiosApi.get("/company/" + companyId + "/check-promo?promo=" + promo, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    sendRequestAddUsersToCompanyPaymentTest(companyId, users) {
        return axiosApi.post("/company/" + companyId + "/requests-add-users-pay-test?users=" + users, {}, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    updateCompany(id, name, phone, activity, employees, adminId) {
        return axiosApi.patch("/company/" + id, {
            name: name,
            phone: phone,
            fieldOfActivity: activity,
            gapOfEmployees: employees,
            representativeId: adminId
        }, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },

    updateCompanyAdmin(id, name, phone, activity, employees, adminId) {
        return axiosApi.patch("/company/" + id + "/admin", {
            name: name,
            phone: phone,
            fieldOfActivity: activity,
            gapOfEmployees: employees,
            representativeId: adminId
        }, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    sendCompanyRegistrationRequest(name, phone, activity, employees, promocode) {
        return axiosApi.post("/company/registration", {
            name: name,
            phone: phone,
            activity: activity,
            employees: employees,
            promocode: promocode
        }, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    sendCompanyRegistrationWithoutRegister(nameUser, email, nameCompany, phone, activity, employees, promocode) {
        return axiosApi.post("/company/registration-without-register", {
            nameUser: nameUser,
            email: email,
            nameCompany: nameCompany,
            phone: phone,
            activity: activity,
            employees: employees,
            promocode: promocode
        }, {})
    },
    sendCompanychangeTariffPremium(name, phone, companyId) {
        return axiosApi.post(`/company/${companyId}/requests-premium`, {
            name: name,
            phone: phone
        }, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    sendFeedbackForTalent(form) {
        const raw = toRaw(form);
        return axiosApi.post("/talent-feedback", {
            estimation: raw.estimation,
            improvements: raw.improvements,
            difficulties: raw.difficulties,
            agreement: raw.agreement,
            useful: raw.useful,
            likeAndMissing: raw.likeAndMissing,
            recommendation: raw.recommendation,
            goals: raw.goals,
            source: raw.source,
        }, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },


    changeRoleInTeam(teamId, userId, role) {
        return axiosApi.patch(`/teams/${teamId}/users/${userId}`, {role: role}, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    getUsersWithoutTeam(limit, offset, search) {
        return axiosApi.get("/admin/users/without-teams?offset=" + offset + "&limit=" + limit + "&search=" + search, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    changeRoleInCompany(entityId, userId, role) {
        return axiosApi.post(`/admin/users/change-user-teams?entityId=${entityId}&userId=${userId}`, { role: role }, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    getUsersWithTeams(limit, offset) {
        return axiosApi.get("/admin/users/with-teams?offset=" + offset + "&limit=" + limit, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    getUsersWithTeamsSearch(limit, offset, search) {
        return axiosApi.get("/admin/users/with-teams?offset=" + offset + "&limit=" + limit + "&search=" + search, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    getAdminPage() {
        return axiosApi.get('/admin', {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    getRecoveredTeamByDate(startDate, endDate) {
        return axiosApi.get('/admin/companies/recovery?startDate=' + startDate + '&endDate=' + endDate, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    sendChangeRoleAccess(email, isSuperAdmin) {
        return axiosApi.post('/admin/users/make-superadmin?email=' + email+ '&admin=' + isSuperAdmin, {}, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }})
    },
    verifyEmail(token) {
        return axiosApi.get("/verify?t=" + token,)
    },
    sendVerificationEmail() {
        return axiosApi.post("/verify", {}, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    deleteUserFromTeam(teamId, userId) {
        return axiosApi.delete("/teams/" + teamId + "/users/" + userId, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    sendTalentStatistics(stats) {
        return axiosApi.post("/talent-statistics?", {
            answers: stats,
            email: store.state.AuthModule.credentials.username
        }, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    changePasswordByRecovery(token, newPassword) {
        return axiosApi.post("/password-recovery/change", {
            token: token,
            newPassword: newPassword
        })
    },
    createPasswordRecovery(email) {
        return axiosApi.post("/password-recovery", {
            host: location.host,
            email: email
        })
    },
    deleteUser(userId) {
        return axiosApi.delete("/users/" + userId, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    addAccessesToCompany(companyId, accesses) {
        return axiosApi.post("/company/" + companyId + "/add-users?users=" + accesses, {}, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        });
    },
    reduceAccessToCompany(companyId, accesses){
        return axiosApi.post("/company/" + companyId + "/reduce-users?users=" + accesses, {}, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        });
    },
    deleteTeam(teamId) {
        return axiosApi.delete("/teams/" + teamId, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    login(email, password) {
        return axiosApi.get("/users/login", {
            auth: {
                username: email, password: password
            }
        })
    },
    verifyAcc() {
        return axiosApi.get('/users/activate?email=' + store.getters['AuthModule/getUsername'])
    },
    getTests() {
        return axios.get(backendUrl + '/resources/test1.json');
    },
    getLevelTests() {
        return axios.get(backendUrl + '/resources/test_level.json');
    },
    getTypesInfo() {
        return axios.get(backendUrl + '/resources/types_info.json');
    },
    getLevelInfo() {
        return axios.get(backendUrl + '/resources/levels_info.json');
    },
    changePassword(data) {
        return axiosApi.patch('/users/me/password', data, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    createUser(email, password, firstName, lastName, phone, token) {
        let body;
        if (token != '' && token != null) {
            body = {
                email: email,
                password: password,
                firstName: firstName,
                lastName: lastName,
                phone: phone,
                token: token,
                host: location.host
            }
        } else {
            body = {
                email: email, password: password, firstName: firstName, lastName: lastName, phone: phone, host: location.host
            }
        }
        return axiosApi.post('/users', body)
    },
    addResultToUser(result) {
        return axiosApi.post('/users/me/talent', result, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    verifyTalentResult() {
        return axiosApi.get('/users/me/talent/verify', {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    getCurrentUser() {
        return this.getUserById('me')
    },

    getUserById(id) {
        return axiosApi.get("/users/" + id, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    updateUser(firstName, lastName, position, hideTalent, phone) {
        return axiosApi.patch("/users/me", {
            firstName: firstName, lastName: lastName, position: position, hideTalent: hideTalent, phone: phone
        }, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    updateUserById(id, firstName, lastName, phone, position, hideTalent) {
        return axiosApi.patch("/users/" + id, {
            firstName: firstName, lastName: lastName, phone: phone, position: position, hideTalent: hideTalent
        }, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    getTokenDesctiption(token) {
        return axiosApi.get("/token/describe?token=" + token);
    },
    getTokenForTeam(id) {
        return axiosApi.get("/token/team/" + id, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    getAdminToken(id) {
        return axiosApi.get("/token/admin/" + id, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    applyToken(token) {
        return axiosApi.get("/token/apply?token=" + token, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        });
    },
    getSelfCompanies() {
        return axiosApi.get("/users/me/company", {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    getCompaniesWhereUserIsAdmin() {
        return axiosApi.get("/users/me/admin", {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    getCompanyById(id) {
        return axiosApi.get("/company/" + id, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },

    getTeamById(id) {
        return axiosApi.get("/teams/" + id, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    getTeamViewById(teamId, viewId) {
        return axiosApi.get("/teams/" + teamId + "/users/view/" + viewId, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    getSelfTeams() {
        return axiosApi.get("/users/me/teams", {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    getSelfJoinRequests() {
        return axiosApi.get("/users/me/join-requests", {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    createJoinRequest(teamId, email) {
        return axiosApi.post("/teams/" + teamId + "/join-requests?email=" + email, {}, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    getTeamJoinRequests(teamId) {
        return axiosApi.get("/teams/" + teamId + "/join-requests", {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    acceptJoinRequests(id) {
        return axiosApi.get("/users/me/join-requests/" + id + "/accept", {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    declineJoinRequests(id) {
        return axiosApi.get("/users/me/join-requests/" + id + "/decline", {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    addTeamToCompany(companyId, name) {
        return axiosApi.post("/company/" + companyId + "/teams", {name: name}, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    getAllCompanies() {
        return axiosApi.get("/company", {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    createCompany(name) {
        return axiosApi.post("/company", {name: name}, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    deleteCompany(id) {
        return axiosApi.delete("company/"+ id, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    renameTeam(teamId, newName) {
        return axiosApi.patch("/teams/" + teamId, { name: newName }, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },

    getLevelResult() {
        return axiosApi.get("/users/me/consciousness", {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    addLevelResultToUser(data, avg) {
        return axiosApi.post("/users/me/consciousness", {
            result: data,
            avg: avg
        }, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    sendTestEmail(email) {
        return axiosApi.get("/email/test?email=" + email, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    sendForm(name, email, phone, additionalData) {
        return axiosApi.post("/email/landing",
            {
                email: email,
                name: name,
                phone: phone,
                additional: additionalData
            }
        )
    },
    sendFormPromo(name, phone, promo, additionalData) {
        return axiosApi.post("/email/landing-promo",
            {
                promo: promo,
                name: name,
                phone: phone,
                additional: additionalData
            }
        )
    },
    getUsersAllowedTeam(team_id){
        return axiosApi.get(`/teams/${team_id}/users-allowed`, {
            auth: {
                username: store.state.AuthModule.credentials.username,
                password: store.state.AuthModule.credentials.password
            }
        })
    },
    setUsersAllowedTeam(team_id, usersId) {
        return axiosApi.post(`/teams/${team_id}/users-allowed`,
            {
                usersId: usersId
            },{
                auth: {
                    username: store.state.AuthModule.credentials.username,
                    password: store.state.AuthModule.credentials.password
                }
            }
        )
    },
    setResSealed(team_id, isResSealed) {
        return axiosApi.post(`/teams/${team_id}/seal-res?seal=${isResSealed}`,{},
            {
                auth: {
                    username: store.state.AuthModule.credentials.username,
                    password: store.state.AuthModule.credentials.password
                }
            }
        )
    },
    setTeamSealed(team_id, isTeamSealed) {
        return axiosApi.post(`/teams/${team_id}/seal-team?seal=${isTeamSealed}`,{},
            {
                auth: {
                    username: store.state.AuthModule.credentials.username,
                    password: store.state.AuthModule.credentials.password
                }
            }
        )
    },
    setOthersSealed(team_id, isOthersSealed) {
        return axiosApi.post(`/teams/${team_id}/seal-other?seal=${isOthersSealed}`,{},
            {
                auth: {
                    username: store.state.AuthModule.credentials.username,
                    password: store.state.AuthModule.credentials.password
                }
            }
        )
    },
    getUserShareResult(token) {
        return axiosApi.get("/users/share/"+ token, {})
    }
}
